import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Button } from '@mui/material';
// hooks
import useAuth from '../hooks/useAuth';
// components
import { MotionContainer, varBounce } from '../components/animate';
import Page from '../components/Page';
// assets
import { ForbiddenIllustration } from '../assets';
import { Navigate } from 'react-router-dom';


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  hasContent: PropTypes.bool,
  role: PropTypes.string, // Example ['admin', 'leader']
  children: PropTypes.node,
  isRoute: PropTypes.bool,
};

export default function RoleBasedGuard({ hasContent, role, children, isRoute }) {
  // Logic here to get current user role
  const { user } = useAuth();

  // const currentRole = 'user';
  const currentRoles = user?.roles;

  if (isRoute === true && typeof currentRoles !== 'undefined' && currentRoles.includes("user")) {
    return <Navigate to="/app" replace />;
  } else if (isRoute && typeof currentRoles !== 'undefined' && currentRoles.includes("superadmin")) {
    return <Navigate to="/super-admin" replace />;
  } else if (isRoute && typeof currentRoles === 'undefined') {
    return <Navigate to="/app" replace />;
  } else if (typeof currentRoles !== 'undefined' && !currentRoles.includes(role)) {
    return hasContent ? (
      <Page title="Permission Denied">
        <RootStyle>
          <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
            <m.div variants={varBounce().in}>
              <ForbiddenIllustration sx={{ height: 260, my: { xs: 10, sm: 5 } }} />
            </m.div>

            <m.div variants={varBounce().in}>
              <Typography variant="h3" paragraph>
                Permission Denied
              </Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <Typography sx={{ color: 'text.secondary' }}>You do not have permission to access this page</Typography>
            </m.div>

            {(currentRoles.includes("superadmin")) && (
              <m.div variants={varBounce().in}>
                <Button sx={{ mt: 2 }} href='/super-admin' size="large" variant="contained">
                  Goto Super Admin
                </Button>
              </m.div>)}
          </Container>
        </RootStyle>
      </Page>
    ) : null;
  }

  return <>{children}</>;
}