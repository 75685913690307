// theme
import palette from '../theme/palette';

// ----------------------------------------------------------------------

export const colorPresets = [
  // DEFAULT
  {
    name: 'default',
    ...palette.light.primary,
  },
  // BLUE
  {
    name: 'blue',
    lighter: '#67A6E6',
    light: '#4187CC',
    main: '#0063C6',
    dark: '#0E58A1',
    darker: '#3A526B',
    contrastText: '#fff',
  },
];

export const defaultPreset = colorPresets[0];
export const bluePreset = colorPresets[1];

export default function getColorPresets(presetsKey) {
  return {
    blue: bluePreset,
    default: defaultPreset,
  }[presetsKey];
}
